<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-form>
      <b-row>
        <!-- Name Building -->
        <b-col cols="12">
          <b-form-group label-for="name-building">
            <template v-slot:label>
              Tên tòa nhà <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tên tòa nhà"
              rules="required"
            >
              <b-form-input
                id="name-building"
                v-model="dataBuilding.buildingName"
                name="nameBuilding"
                placeholder="Tên tòa nhà"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Đơn vị chủ quản"
            label-for="organizations"
          >
            <b-form-select
              id="status"
              v-model="dataBuilding.organization"
              :options="optOrganizations"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label-for="status">
            <template v-slot:label>
              Thứ tự <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tên tòa nhà"
              rules="required"
            >
              <b-form-input
                id="order_no"
                v-model="dataBuilding.orderNo"
                type="number"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Trạng thái"
            label-for="status"
          >
            <b-form-select
              id="status"
              v-model="dataBuilding.status"
              :options="optionsStatus"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="float-left">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BCol, BRow, BForm, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'FormBuilding',
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  props: {
    dataBuilding: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      formErr: {
        nameBuilding: '',
        maToaNha: '',
      },
      optionsStatus: [],
      optOrganizations: [],
      required,
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      organizations: 'building/organizations',
      statuses: 'building/statuses',
    }),
  },
  async created() {
    // init data FROM API
    await this.getInit()
    this.optOrganizations = this.organizations.map(item => ({ value: item.id, text: item.name }))
    if (!this.dataBuilding.organization) {
      this.dataBuilding.organization = this.optOrganizations[0].value
    }
    this.optionsStatus = this.statuses.map(item => ({ value: item.id, text: item.name }))
    if (!this.dataBuilding.status) {
      this.dataBuilding.status = 1
    }
  },
  methods: {
    ...mapActions({
      getInit: 'building/getInit',
    }),
    // handle even
    handleSaveData(e) {
      const data = this.dataBuilding
      this.$emit('saveDataBuilding', data)
      e.preventDefault()
    },

    resetForm() {
      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped>

</style>
